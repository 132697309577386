import React, { useRef, useState, useEffect } from 'react';
import styled from 'styled-components';
import { breakpoints, respondTo } from '../../util/breakpoints';
import * as colors from '../../util/colors';
import { Link } from 'gatsby';
import { Legal, UtilityBar, LinkStyle, Subtitle, MobileMenuStyle, H6Style, BodyStyle, DesktopOnly, MobileOnly } from '../../util/typography';
import downIcon from '../../assets/utility/icons/icon-carat-down.svg';
import upIcon from '../../assets/utility/icons/icon-carat-up.svg';
import rightIcon from '../../assets/utility/icons/icon-carat-right.svg';
import leftIcon from '../../assets/utility/icons/icon-carat-left.svg';
import downloadIcon from '../../assets/utility/icons/icon-button-download.svg';
import downButtonIcon from '../../assets/utility/icons/arrow-down.svg';
import keylenLogo from '../../assets/keytruda-lenvima-logo.png';
import keylenLogoMobile from '../../assets/keytruda-lenvima-logo-mobile.png';
import mobileMenuIcon from '../../assets/utility/icons/icon-mobile-menu.svg';
import closeIcon from '../../assets/utility/icons/icon-close-x.svg';
import rccIcon from '../../assets/content-icons/indication-icons-rcc.png';
import hnsccIcon from '../../assets/content-icons/indication-icons-hnscc.png';
import useOnClickOutside from 'use-onclickoutside';
import { GLOBAL_NAV_MENU, MEGAMENU, MOBILE_MENU, MOBILE_NAV_OVERLAY, NAV_CONTAINER_MOBILE_MENU_OPEN, NAV_CONTAINER , NAV_HEADER, UTILITY_NAV_MENU } from '../../constants/z-index-map';
import Button from '../Button';
import PageHeader from '../PageHeader';
import AnchorNav from '../AnchorNav';
import {  useDispatch, useSelector } from 'react-redux';
import { AnchorLink } from "gatsby-plugin-anchor-links";
import { externalDotWhite, arrowExternalWhite, arrowRightGreenDark, arrowRightGreenRollover, arrowRightWhite } from '../../util/icons';
import Headroom from 'react-headroom';
import {
    openGeneralModal,
    allowScrolling,
    preventScrolling,
    openOverlay,
} from '../../store/actions';
import { createOverlay } from '../../components/Overlay';
import './nav-headroom.css';

const NavBarContainer = styled.nav`
    z-index: ${({ mobileMenuOpen }) =>
        mobileMenuOpen ? NAV_CONTAINER_MOBILE_MENU_OPEN : NAV_CONTAINER};
    position: sticky;
    top: 0px;
    width: 100%;
    ${({anchorNav}) => anchorNav && `
        margin-bottom: 46px;
    `}
`;

const splitGradient = (leftColor, rightColor) =>
    `linear-gradient(to right, ${leftColor} 0%, ${leftColor} 50%, ${rightColor} calc(50% + 1px), ${rightColor} 100%)`;

export const NAV_OVERLAY_KEY = 'NAV_OVERLAY';

const MobileNavOverlay = createOverlay(NAV_OVERLAY_KEY, {
    extendsOverlay: StyledOverlay =>
        styled(StyledOverlay)`
            display: ${({ isActive }) => (isActive ? 'block' : 'none')};
            z-index: ${MOBILE_NAV_OVERLAY};

            /* Never render on Desktop */
            @media (min-width: ${breakpoints.md}) {
                /* @todo Use real values for the media query */
                display: none;
            }
        `,
});

const NavHeader = styled.div`
    z-index: ${NAV_HEADER};
    transform: translateY(0%);
    transition: transform 200ms ease-in-out;
    ${({pageHeaderHidden}) => pageHeaderHidden && `
        height: 49px;
    `}
    ${respondTo.md`
        ${({pageHeaderHidden}) => pageHeaderHidden && `
            height: 80px;
        `}
    `}
    ${({hide}) => hide && `
        transform: translateY(-100%);
        position: relative;
    `}
`;

const GlobalNav = styled.div`
    height: 49px;
    background: ${colors.white};
    z-index: ${GLOBAL_NAV_MENU};
    position: relative;

    ${respondTo.md`
        height: 80px;
        border-bottom: 2px solid ${colors.pebble};
    `}
`;

const GlobalNavContainer = styled.div`
    display: flex;
    justify-content: space-between;
    max-width: 1032px;
    padding: 0 15px;
    margin: 0 auto;
    position: relative;
    align-items: center;
    height: inherit;
    ${respondTo.md`
        padding: 0;
        height: 80px;
    `}
`;

const Logo = styled.img`
    width: 210px;
    margin: auto 0;
    ${respondTo.md`
        width: 273px;
    `}
`;

const UtilityNav = styled.div`
    background: ${colors.charcoal};
    z-index: ${UTILITY_NAV_MENU};
    position: relative;
`;

const UtilityNavContainer = styled.div`
    display: flex;
    padding: 0px 4px;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    height: 41px;
    max-width: 1032px;
    margin: 0 auto;
    ${respondTo.md`
        padding: 0px 30px;
    `}
`;

const SupportingLinks = styled.div`
    display: flex;
    gap: 15px;
    ${Legal};
    ${respondTo.md`
        ${UtilityBar};
    `}
`;

const SupportingLinkButton = styled.button`
    height: 41px;
    border: 0;
    background: none;
    color: ${colors.white};
    cursor: pointer;
    padding: 6px;
    display: flex;
    align-items: center;
    img {
        width: 8px;
        margin-left: 4px;
        filter: invert(98%) sepia(2%) saturate(383%) hue-rotate(212deg) brightness(116%) contrast(100%);
    }
    &:hover, &.focus {
        background: white;
        color: ${colors.ink};
        img {
            filter: invert(11%) sepia(0%) saturate(1016%) hue-rotate(191deg) brightness(100%) contrast(90%);
        }
    }
`;

const ExternalLinks = styled.div`
    color: ${colors.white};
    gap: 15px;
    img {
        width: 8px;
        filter: invert(100%) sepia(0%) saturate(1%) hue-rotate(169deg) brightness(102%) contrast(102%);
    }
    a {
        cursor: pointer;
        display: flex;
        gap: 4px;
        text-decoration: none;
        position: relative;
        padding-right: 12px;
        &:after {
            content: '';
            position: absolute;
            right: 0px;
            background-image: url(${externalDotWhite}), url(${arrowExternalWhite});
            background-repeat: no-repeat;
            top: 50%;
            transform: translateY(-50%);
            width: 8px;
            height: 8px;
            background-position: bottom left, top right;
            background-size: 2px 2px, 6px 6px, 200% 100%;
        }
        &:hover {
            &:after {
                animation-duration: 0.4s;
                animation-name: external-animation;
            }
        }
    }
    a:visited, a {
        color: ${colors.white};
    }
    ${Legal};
    display: none;
    ${respondTo.md`
        ${UtilityBar};
        display: flex;
    `}
    @keyframes external-animation {
        0% {
            background-position: bottom left, center 50%, left;
        }

        50% {
            background-position: bottom left, calc(100% + 10px) calc(0% - 10px), center;
        }

        50.001% {
            background-position: bottom left, calc(0% - 10px) calc(100% + 10px), center;
        }

        100% {
            background-position: bottom left, center 50%, right;
        }
    }
`;

const SupportingLink = styled.div`
    position: relative;
`;

const SupportingLinkMenu = styled.div`
    position: absolute;
    top: 41px;
    background: ${colors.white};
    padding: 12px 6px 6px 6px;
    display: flex;
    gap: 8px;
    flex-direction: column;
    z-index: ${UTILITY_NAV_MENU};
    box-shadow: 0px 12px 12px 0px rgba(0, 0, 0, 0.25);

    &.patient-info {
        left: 50%;
        transform: translateX(-50%);
    }
    &.ssi-info {
        right: 0;
    }
    ${respondTo.md`
        &.patient-info, &.ssi-info {
            left: 0;
            transform: none;
        }
    `}
`;

const SupportingLinkMenuButton = styled.a`
    padding: 0;
    display: flex;
    gap: 8px;
    color: ${colors.brandGreen};
    border-radius: 0;
    background: none;
    border: 0;
    align-items: center;
    justify-content: space-between;
    width: 134px;
    text-decoration: none;
    ${LinkStyle};
    img {
        width: 12px;
        filter: invert(49%) sepia(78%) saturate(374%) hue-rotate(71deg) brightness(96%) contrast(87%);
    }
    &:hover {
        color: ${colors.greenDark};
        img {
            filter: invert(32%) sepia(86%) saturate(363%) hue-rotate(82deg) brightness(87%) contrast(83%);
        }
    }
   &.lenvima {
    color: ${colors.mulberry};
    img {
        filter: invert(16%) sepia(43%) saturate(3991%) hue-rotate(301deg) brightness(93%) contrast(123%);
    }
    &:hover {
        color: ${colors.mulberryHover};
        img {
            filter: invert(7%) sepia(41%) saturate(4596%) hue-rotate(303deg) brightness(102%) contrast(110%);
        }
    }
   }
   &.patient-info {
    width: 240px;
   }
   &.ssi-info {
    width: 157px;
   }
`;

const Links = styled.div`
    display: none;
    position: absolute;
    right: 0;
    top: 0;
    height: calc(100% + 2px);
    ${respondTo.md`
        display: flex;
    `}
`;

const LinkButton = styled(Link)`
    padding: 22px 30px 20px 30px;
    color: ${colors.ink};
    display: flex;
    border: 0;
    background: none;
    cursor: pointer;
    align-items: start;
    position: relative;
    border-bottom: 2px solid transparent;
    text-decoration: none;
    height: 80px;
    position: relative;
    &:after {
        content: '';
        position: absolute;
        right: 0;
        top: 22px;
        width: 1px;
        height: calc(100% - 42px);
        background: ${colors.pebble};
    }
    &:last-child:after{
        display: none;
    }
    &:hover {
        background: ${colors.cloud};
    }
    &:hover, &.focus{
        border-bottom: 2px solid ${colors.mulberryLine};
    }
    img {
        width: 11.9px;
        position: absolute;
        bottom: 20px;
        left: 50%;
        transform: translateX(-50%);
        filter: invert(89%) sepia(1%) saturate(0%) hue-rotate(194deg) brightness(88%) contrast(87%);
    }
    a:visited {
        color: ${colors.ink};
    }
`;

const MobileMenu = styled.div`
   width: 274px;
   height: 696px;
   background: ${colors.white};
   position: absolute;
   top: 0;
   right: 0;
   z-index: ${MOBILE_MENU};
   transform: translateX(100%);
   transition: transform 0.5s ease-in-out 0s;
   &.open {
        transform: translateX(0);
   }
   ${respondTo.md`
        display: none;
   `}
`;

const MobileMenuButton = styled.button`
    background: none;
    border: 0;
    padding: 0;
    color: ${colors.greenDark};
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    gap: 5px;
    ${MobileMenuStyle};
    img {
        width: 25px;
        filter: invert(36%) sepia(18%) saturate(1399%) hue-rotate(82deg) brightness(90%) contrast(87%);
    }
   ${respondTo.md`
        display: none;
   `}
`;

const CloseBar = styled.div`
    height: 60px;
    border-bottom: 1px solid ${colors.pebble};
    display: flex;
    button[class*='__MobileMenuButton'] {
        border-left: 1px solid ${colors.pebble};
        height: 60px;
        margin-left: auto;
        padding: 0 6.24px 0 7.24px;
    }
`;

const MobileMenuOverlay = styled.div`
    position: fixed;
    inset: 0px;
    height: 100vh;
    z-index: ${MOBILE_NAV_OVERLAY};
    background-color: rgba(38, 38, 38, 0.8);
    opacity: 1;
    transition: opacity 1s ease-in-out 0.1s;
    overscroll-behavior: contain;
`;

const MainNavItems = styled.div`
    padding: 31.5px 19.07px 25.5px 20.07px;
    display: flex;
    flex-direction: column;
    gap: 35px;
    color: ${colors.charcoal};
    border-bottom: 1px solid ${colors.pebble};
    a, button {
        text-decoration: none;
        width: 100%;
        color: ${colors.charcoal};
    }
    a:visited {
        color: ${colors.charcoal};
    }
    button:first-child{
        display: flex;
        justify-content: space-between;
        align-items: center;
        background: none;
        padding: 0;
        border: 0;
        width: 100%;
        cursor: pointer;
        img {
            filter: invert(32%) sepia(7%) saturate(3929%) hue-rotate(82deg) brightness(100%) contrast(82%);
        }
    }
`;

const UtilityNavItems = styled.div`
    display: flex;
    flex-direction: column;
    gap: 25px;
    padding: 25px 20px 0 20px;
    button, a {
        &:not(:first-child){
            color: ${colors.charcoal};
        }
    }
    .external {
        position: relative;
        padding: 0 30px 0 0;
        &:after {
            content: '';
            position: absolute;
            right: 0px;
            background-image: url(${externalDotWhite}), url(${arrowExternalWhite}), ${splitGradient(colors.greenDark, colors.greenRollOver)};
            background-repeat: no-repeat;
            top: 50%;
            transform: translateY(-50%);
            border-radius: 50%;
            width: 22px;
            height: 22px;
            background-position: 30% 70%, 60% 40%, left;
            background-size: 3px 3px, 8px 8px, 200% 100%;
        }
        &:hover {
            &:after {
                animation-duration: 0.4s;
                animation-name: external-animation1;
            }
        }
    }
    @keyframes external-animation1 {
        0% {
            background-position: 30% 70%, center 50%, left;
        }

        50% {
            background-position: 30% 70%, calc(100% + 10px) calc(0% - 10px), center;
        }

        50.001% {
            background-position: 30% 70%, calc(0% - 10px) calc(100% + 10px), center;
        }

        100% {
            background-position: 30% 70%, center 50%, right;
        }
    }
`;

const AskMerckButton = styled(Link)`
    border: 0;
    text-decoration: none;
    background: ${colors.greenDark};
    padding: 12px 20px;
    display: flex;
    gap: 20px;
    cursor: pointer;
    color: ${colors.white};
    border-radius: 4px;
    justify-content: space-between;
    ${H6Style};
    position: relative;
    &:after {
        content: '';
        position: absolute;
        right: 20px;
        background-image: url(${externalDotWhite}), url(${arrowExternalWhite});
        background-repeat: no-repeat;
        top: 50%;
        transform: translateY(-50%);
        width: 16px;
        height: 16px;
        background-position: bottom left, top right;
        background-size: 4px 4px, 12px 12px, 200% 100%;
    }
    &:hover {
        &:after {
            animation-duration: 0.4s;
            animation-name: external-animation;
        }
    }
`;

const MegaMenu = styled.div`
    width: min(1032px, 100%);
    padding: 30px;
    background: ${colors.silver};
    position: absolute;
    left: 0;
    top: 80px;
    gap: 10px;
    justify-content: center;
    flex-wrap: wrap;
    z-index: ${MEGAMENU};
    transform: translateY(-100%);
    display: none;
    transition: transform 0.5s ease-in-out 0s;
    ${respondTo.md`
        &.open {
            transform: translateY(0);
            display: flex;
        }
   `}
`;

const MegaMenuLink = styled(Link)`
    padding: 15px 31px 15px 10px;
    background: ${colors.white};
    ${BodyStyle};
    line-height: normal;
    font-weight: 700;
    border-radius: 0px;
    color: ${colors.greenDark};
    font-size: 18px;
    width: 251px;
    display: flex;
    gap: 15px;
    align-items: center;
    text-decoration: none;
    border-bottom: 1px solid ${colors.pebble};
    position: relative;
    &:after {
        content: '';
        position: absolute;
        right: 0px;
        background-image: url(${arrowRightWhite});
        background-color: green;
        background-repeat: no-repeat;
        top: 50%;
        transform: translateY(-50%);
        width: 16px;
        height: 16px;
        background-position: center left;
        border-radius: 50%;
        background-size: 15px 10px, 200% 100%;
    }
    /* &:hover{
        color: ${colors.greenRollOver};
        &:after {
            animation-duration: 0.3s;
            animation-name: arrowRight-animation;
            animation-fill-mode: both;
        }
    } */
    ${respondTo.md`
        width: calc(50% - 5px);
        padding: 10px;
        gap: 10px;
        border-bottom: 0;
        border-radius: 6px;
        font-size: 16px;
    &:after {
        content: '';
        position: absolute;
        right: 15px;
        background: url(${arrowRightGreenDark});
        background-repeat: no-repeat;
        top: 50%;
        transform: translateY(-50%);
        width: 12px;
        height: 12px;
        background-position: center left;
    }
    &:hover{
        color: ${colors.greenRollOver};
        ${respondTo.md`
            box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.35);
        `}
        &:after {
            background: url(${arrowRightGreenRollover});
            background-repeat: no-repeat;
            animation-duration: 0.3s;
            animation-name: arrowRight-animation;
            animation-fill-mode: both;
        }
    }
    @keyframes arrowRight-animation {
        0% {
            background-position: center 50%, left;
        }

        50% {
            background-position: calc(100% + 20px) center, center;
        }

        50.001% {
            background-position: calc(0% - 20px) center, center;
        }

        100% {
            background-position: center 50%, right;
        }
    }
`}
`;

const MegaMenuIcon = styled.img`
    width: 40px;
`;

const MobileMegaMenu = styled.div`
    position: absolute;
    width: 100%;
    height: calc(100% - 60px);
    background: ${colors.white};
    top: 60px;
    transform: translateX(100%);
    transition: transform 0.5s ease-in-out 0s;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    overflow-y: scroll;
    &.open {
         transform: translateX(0);
    }
`;
const MobileResourceMenu = styled.div`
    position: absolute;
    width: 100%;
    height: calc(100% - 60px);
    background: ${colors.white};
    top: 60px;
    transform: translateX(100%);
    transition: transform 0.5s ease-in-out 0s;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    overflow-y: scroll;
    &.open {
         transform: translateX(0);
    }
`;

const MegaMenuClose = styled.button`
    padding: 0;
    background: none;
    border: 0;
    display: flex;
    align-items: center;
    margin-left: 20px;
    gap: 21px;
    cursor: pointer;
    img {
        filter: invert(32%) sepia(7%) saturate(3929%) hue-rotate(82deg) brightness(100%) contrast(82%);
    }
    h6 {
        line-height: 24px;
        color: ${colors.charcoal};
    }
`;

const megamenuLinks = [
    {
        title: 'Advanced Renal Cell Carcinoma',
        icon: rccIcon,
        to: '/advanced-renal-cell-carcinoma/'
    },
    {
        title: 'Advanced Endometrial Carcinoma',
        icon: hnsccIcon,
        to: '/endometrial-carcinoma/'
    },
];
const resourcesMenuLinks = [
    {
        title: 'HCP Resources',
        icon: rccIcon,
        to: '/professional-resources/'
    },
    {
        title: 'Mechanisms of Action',
        icon: hnsccIcon,
        to: '/mechanisms-of-action/'
    },
];
const ResourcesMenu = styled.div`
    width: min(1032px, 30%);
    padding: 30px;
    background: ${colors.silver};
    position: absolute;
    left: 70%;
    top: 80px;
    gap: 10px;
    justify-content: center;
    flex-wrap: wrap;
    z-index: ${MEGAMENU};
    transform: translateY(-100%);
    display: none;
    transition: transform 0.5s ease-in-out 0s;
    ${respondTo.md`
        &.open {
            transform: translateY(0);
            display: flex;
        }
    `}
    
`;

const ResourcesMenuLink = styled(Link)`
    padding: 15px 31px 15px 10px;
    
    ${BodyStyle};
    line-height: normal;
    font-weight: 700;
    border-radius: 0px;
    color: ${colors.greenDark};
    font-size: 18px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-decoration: none;
    border-bottom: 1px solid ${colors.pebble};
    position: relative;
    &:after {
        content: '';
        position: absolute;
        right: 15px;
        background-repeat: no-repeat;
        top: 50%;
        transform: translateY(-50%);
        width: 16px;
        height: 16px;
        background-position: center;
        background-size: 15px 10px;
    }
    ${respondTo.md`
        width: 100%;
        padding: 10px;
        gap: 10px;
        border-bottom: 0;
        border-radius: 6px;
        font-size: 16px;
        &:after {
            content: '';
            position: absolute;
            right: 15px;
            background: url(${arrowRightGreenDark});
            background-repeat: no-repeat;
            top: 50%;
            transform: translateY(-50%);
        }
        &:hover{
            color: ${colors.greenRollOver};
    
&:after {
                background: url(${arrowRightGreenRollover});
                background-repeat: no-repeat;
                animation-duration: 0.3s;
                animation-name: arrowRight-animation;
                animation-fill-mode: both;
            }
        }
        @keyframes arrowRight-animation {
            0% {
                background-position: center 50%, left;
            }
 
            50% {
                background-position: calc(100% + 20px) center, center;
            }
 
            50.001% {
                background-position: calc(0% - 20px) center, center;
            }
 
            100% {
                background-position: center 50%, right;
            }
    `}
    
`;

const ResourcesMenuIcon = styled.img`
    width: 20px;
    height: 20px;
`;

const Arrow = styled.span`
    display: none;
    margin-left: 10px;
    width: 10px;
    height: 10px;
    border-right: 2px solid #fff;
    border-bottom: 2px solid #fff;
    transform: rotate(-45deg);
`;


const NavBar = ({location, name, anchorNavLinks, icon, iconAlt}) => {

    const [piOpened, setPiOpened] = useState(false);
    const [paiOpened, setPaiOpened] = useState(false);
    const [ssiOpened, setSsiOpened] = useState(false);
    const [mobilePiOpened, setMobilePiOpened] = useState(false);
    const [mobilePaiOpened, setMobilePaiOpened] = useState(false);
    const [mobileSsiOpened, setMobileSsiOpened] = useState(false);
    const [mobileMenuOpened, setMobileMenuOpened] = useState(false);
    const [megaMenuOpened, setMegaMenuOpened] = useState(false);
    const [resourcesMenuOpened, setResourcesMenuOpened] = useState(false);
    const [mobileMegaMenuOpened, setMobileMegaMenuOpened] = useState(false);
    const [mobileResourceMenuOpened, setMobileResourceMenuOpened] = useState(false);
    const [hidePageHeader, setHidePageHeader] = useState(false);

    const piLink = useRef(null);
    const paiLink = useRef(null);
    const ssiLink = useRef(null);
    const mPiLink = useRef(null);
    const mPaiLink = useRef(null);
    const mSsiLink = useRef(null);
    const megaMenu = useRef(null);
    const resMenu = useRef(null);
    const mobileMenu = useRef(null);
    const efficacyButton = useRef(null);
    const resourceButton = useRef(null);

    const scrollLock = useSelector(state => state.scrollLock);
    const generalModalOpen = useSelector(state => state.generalModalOpen);
    const dispatch = useDispatch();

    useOnClickOutside(piLink, e => {
        setPiOpened(false);
    });

    useOnClickOutside(paiLink, e => {
        setPaiOpened(false);
    });

    useOnClickOutside(ssiLink, e => {
        setSsiOpened(false);
    });

    useOnClickOutside(mPiLink, e => {
        setMobilePiOpened(false);
    });

    useOnClickOutside(mPaiLink, e => {
        setMobilePaiOpened(false);
    });

    useOnClickOutside(mSsiLink, e => {
        setMobileSsiOpened(false);
    });

    useOnClickOutside(megaMenu, e => {
        const isEfficacyButton = e.target === efficacyButton.current;
        console.log(efficacyButton.current)
        let isEfficacyChildren = false;
        efficacyButton.current.childNodes.forEach((node) => {
            if(node === e.target){
                isEfficacyChildren = true;
            }
        }) 
        if( !isEfficacyButton && !isEfficacyChildren){
            setMegaMenuOpened(false);
        }
    });
    useOnClickOutside(resMenu, e => {
        const isResourceButton = e.target === resourceButton.current;
        console.log(resourceButton.current)
        let isResourceChildren = false;
        resourceButton.current.childNodes.forEach((node) => {
            if(node === e.target){
                isResourceChildren = true;
            }
        }) 
        if( !isResourceButton && !isResourceChildren){
            setResourcesMenuOpened(false);
        }
    });

    useOnClickOutside(mobileMenu, e => {
        if(!generalModalOpen) {
            setMobileMenuOpened(false);
        }
    });

    const mobileMenuLaunchHandler = () => {
        dispatch(openOverlay(NAV_OVERLAY_KEY));
        dispatch(preventScrolling());
    };

    const scrollListener = (e) => {
        setHidePageHeader(window.scrollY !== 0);
    }

    useEffect(() => {
        if(mobileMenuOpened) {
            dispatch(preventScrolling());
        }else{
            dispatch(allowScrolling());
        }
    }, [mobileMenuOpened]);

    useEffect(() => {
        window.addEventListener('scroll', scrollListener);
        return () => {
        window.removeEventListener('scroll', scrollListener);
        };
    }, []);

    return (
        <NavBarContainer anchorNav={anchorNavLinks} mobileMenuOpen={mobileMenuOpened}>
            <UtilityNav>
                <UtilityNavContainer>
                    <SupportingLinks>
                        <DesktopOnly>
                            <SupportingLink ref={piLink} onMouseEnter={() => setPiOpened(true)} onMouseLeave={() =>  setPiOpened(false)} >
                                <SupportingLinkButton className={piOpened ? 'focus' : ''}>
                                    <p>Prescribing Information</p>
                                    {!piOpened && <img src={downIcon} alt=''/>}
                                    {piOpened && <img src={upIcon} alt=''/>}
                                </SupportingLinkButton>
                                {piOpened && 
                                    <SupportingLinkMenu>
                                        <SupportingLinkMenuButton href='https://www.merck.com/product/usa/pi_circulars/k/keytruda/keytruda_pi.pdf' target='_blank'>
                                            KEYTRUDA
                                            <img src={downloadIcon} alt=''/>
                                        </SupportingLinkMenuButton>
                                        <SupportingLinkMenuButton className='lenvima' href='http://www.lenvima.com/pdfs/prescribing-information.pdf' target='_blank'>
                                            LENVIMA
                                            <img src={downloadIcon} alt=''/>
                                        </SupportingLinkMenuButton>
                                    </SupportingLinkMenu>
                                }
                            </SupportingLink>
                        </DesktopOnly>
                        <MobileOnly>
                            <SupportingLink ref={mPiLink}>
                                <SupportingLinkButton className={mobilePiOpened ? 'focus' : ''} onClick={() => {setMobilePiOpened(!mobilePiOpened)}}>
                                    <p>Prescribing Information</p>
                                    {!mobilePiOpened && <img src={downIcon} alt=''/>}
                                    {mobilePiOpened && <img src={upIcon} alt=''/>}
                                </SupportingLinkButton>
                                {mobilePiOpened && 
                                    <SupportingLinkMenu>
                                        <SupportingLinkMenuButton href='https://www.merck.com/product/usa/pi_circulars/k/keytruda/keytruda_pi.pdf' target='_blank'>
                                            KEYTRUDA
                                            <img src={downloadIcon} alt=''/>
                                        </SupportingLinkMenuButton>
                                        <SupportingLinkMenuButton className='lenvima' href='http://www.lenvima.com/pdfs/prescribing-information.pdf' target='_blank'>
                                            LENVIMA
                                            <img src={downloadIcon} alt=''/>
                                        </SupportingLinkMenuButton>
                                    </SupportingLinkMenu>
                                }
                            </SupportingLink>
                        </MobileOnly>
                        <DesktopOnly>
                            <SupportingLink ref={paiLink} onMouseEnter={() => setPaiOpened(true)} onMouseLeave={() => setPaiOpened(false)}>
                                <SupportingLinkButton className={paiOpened ? 'focus' : ''}>
                                    <p>Patient Information</p>
                                    {!paiOpened && <img src={downIcon} alt=''/>}
                                    {paiOpened && <img src={upIcon} alt=''/>}
                                </SupportingLinkButton>
                                {paiOpened && 
                                    <SupportingLinkMenu className='patient-info'>
                                        <SupportingLinkMenuButton className='patient-info' target='_blank' href='https://www.merck.com/product/usa/pi_circulars/k/keytruda/keytruda_mg.pdf'>
                                            KEYTRUDA: Medication Guide
                                            <img src={downloadIcon} alt=''/>
                                        </SupportingLinkMenuButton>
                                        <SupportingLinkMenuButton className='lenvima patient-info' target='_blank' href='https://www.lenvima.com/-/media/Project/EISAI/Lenvima/PDF/prescribing-information.pdf#page=53'>
                                            LENVIMA: Patient Information
                                            <img src={downloadIcon} alt=''/>
                                        </SupportingLinkMenuButton>
                                    </SupportingLinkMenu>
                                }
                            </SupportingLink>
                        </DesktopOnly>
                       <MobileOnly>
                            <SupportingLink ref={mPaiLink} onClick={() => setMobilePaiOpened(!mobilePaiOpened)}>
                                <SupportingLinkButton className={mobilePaiOpened ? 'focus' : ''}>
                                    <p>Patient Information</p>
                                    {!mobilePaiOpened && <img src={downIcon} alt=''/>}
                                    {mobilePaiOpened && <img src={upIcon} alt=''/>}
                                </SupportingLinkButton>
                                {mobilePaiOpened && 
                                    <SupportingLinkMenu className='patient-info'>
                                        <SupportingLinkMenuButton className='patient-info' target='_blank' href='https://www.merck.com/product/usa/pi_circulars/k/keytruda/keytruda_mg.pdf'>
                                            KEYTRUDA: Medication Guide
                                            <img src={downloadIcon} alt=''/>
                                        </SupportingLinkMenuButton>
                                        <SupportingLinkMenuButton className='lenvima patient-info' target='_blank' href='https://www.lenvima.com/-/media/Project/EISAI/Lenvima/PDF/prescribing-information.pdf#page=53'>
                                            LENVIMA: Patient Information
                                            <img src={downloadIcon} alt=''/>
                                        </SupportingLinkMenuButton>
                                    </SupportingLinkMenu>
                                }
                            </SupportingLink>
                       </MobileOnly>
                       <DesktopOnly>
                            <SupportingLink ref={ssiLink} onMouseEnter={() => setSsiOpened(true)} onMouseLeave={() => setSsiOpened(false)}>
                                <SupportingLinkButton className={ssiOpened ? 'focus' : ''}>
                                    <Link>Selected Safety Information</Link>
                                    {!ssiOpened && <img src={downIcon} alt=''/>}
                                    {ssiOpened && <img src={upIcon} alt=''/>}
                                </SupportingLinkButton>
                                {ssiOpened && 
                                    <SupportingLinkMenu className='ssi-info'>
                                        <SupportingLinkMenuButton as={Link} to='#ssi-keytruda' className='ssi-info'>
                                            KEYTRUDA
                                            <img src={downButtonIcon} alt=''/>
                                        </SupportingLinkMenuButton>
                                        <SupportingLinkMenuButton as={Link} to='#ssi-lenvima' className='ssi-info lenvima'>
                                            LENVIMA
                                            <img src={downButtonIcon} alt=''/>
                                        </SupportingLinkMenuButton>
                                    </SupportingLinkMenu>
                                }
                            </SupportingLink>
                       </DesktopOnly>
                        <MobileOnly>
                        <SupportingLink ref={mSsiLink} onClick={() => setMobileSsiOpened(!mobileSsiOpened)}>
                                <SupportingLinkButton className={mobileSsiOpened ? 'focus' : ''}>
                                    <Link>Selected Safety Information</Link>
                                    {!mobileSsiOpened && <img src={downIcon} alt=''/>}
                                    {mobileSsiOpened && <img src={upIcon} alt=''/>}
                                </SupportingLinkButton>
                                {mobileSsiOpened && 
                                    <SupportingLinkMenu className='ssi-info'>
                                        <SupportingLinkMenuButton as={Link} to='#ssi-keytruda' className='ssi-info'>
                                            KEYTRUDA
                                            <img src={downButtonIcon} alt=''/>
                                        </SupportingLinkMenuButton>
                                        <SupportingLinkMenuButton as={Link} to='#ssi-lenvima' className='ssi-info lenvima'>
                                            LENVIMA
                                            <img src={downButtonIcon} alt=''/>
                                        </SupportingLinkMenuButton>
                                    </SupportingLinkMenu>
                                }
                            </SupportingLink>
                        </MobileOnly>
                    </SupportingLinks>
                    <ExternalLinks>
                        <Link href='https://www.keytrudalenvima.com/' target='_blank'>
                            Visit Patient Site
                        </Link>
                        <Link href='https://www.askmerck.com/' target='_blank'>
                            Ask Merck
                        </Link>
                        <a onClick={() => {
                                dispatch(openGeneralModal())
                                dispatch(preventScrolling())
                            }}>
                            Medical Information
                        </a>
                    </ExternalLinks>
                </UtilityNavContainer>
            </UtilityNav>
            <Headroom
                disableInlineStyles={true}
                disable={scrollLock}
                pinStart={130}
                onUnfix={() => {
                    if(document.getElementsByClassName('headroom-wrapper')){
                        document.getElementsByClassName('headroom-wrapper')[0].style.height = 'unset';
                    }
                }}
                onUnpin={() => {
                    if(document.getElementsByClassName('headroom-wrapper')){
                        document.getElementsByClassName('headroom-wrapper')[0].style.height = anchorNavLinks ? '52px' : '0';
                    }
                }}
            >
            <NavHeader pageHeaderHidden={hidePageHeader}>
                <GlobalNav>
                    <GlobalNavContainer>
                        <Link to='/'>
                            <DesktopOnly><Logo src={keylenLogo} alt='KEYTRUDA® (pembrolizumab) and LENVIMA® (lenvatinib) Website for Health Care Professionals' /></DesktopOnly>
                            <MobileOnly><Logo src={keylenLogoMobile} alt='KEYTRUDA® (pembrolizumab) and LENVIMA® (lenvatinib) Website for Health Care Professionals' /></MobileOnly>
                        </Link>
                        <Links>
                            <LinkButton as='button' ref={efficacyButton} className={location.pathname === '/advanced-renal-cell-carcinoma/' || location.pathname === '/endometrial-carcinoma/' ? 'focus': ''} onClick={() => setMegaMenuOpened(!megaMenuOpened)}>
                                <Subtitle>
                                    Efficacy
                                </Subtitle>
                                <img src={downIcon} alt='' />
                            </LinkButton>
                            <LinkButton to='/safety/' className={location.pathname === '/safety/' ||  location.pathname === '/safety' ? 'focus': ''}>
                                <Subtitle>Safety</Subtitle>
                            </LinkButton>
                            <LinkButton to='/dosing/' className={location.pathname === '/dosing/' || location.pathname === '/dosing' ? 'focus': ''}>
                                <Subtitle>Dosing</Subtitle>
                            </LinkButton>
                            <LinkButton to='/patient-support/' className={location.pathname === '/patient-support/' || location.pathname === '/patient-support' ? 'focus': ''}>
                                <Subtitle>Patient Support</Subtitle>
                            </LinkButton>
                            <LinkButton as='button' ref={resourceButton}  className={location.pathname === '/professional-resources/' || location.pathname === '/mechanisms-of-action/' ? 'focus': ''} onClick={() => setResourcesMenuOpened(!resourcesMenuOpened)}>
                                <Subtitle>Resources</Subtitle>
                                <img src={downIcon} alt='' />
                            </LinkButton>
                            {/* <LinkButton as='button' ref={efficacyButton} className={location.pathname === '/advanced-renal-cell-carcinoma/' || location.pathname === '/endometrial-carcinoma/' ? 'focus': ''} onClick={() => setMegaMenuOpened(!megaMenuOpened)}>
                                <Subtitle>
                                    Resource
                                </Subtitle>
                                <img src={downIcon} alt='' />
                            </LinkButton> */}
                        </Links>
                        <MegaMenu className={megaMenuOpened ? 'open' : ''} ref={megaMenu}>
                                {megamenuLinks.map((megamenuLink, i) => (
                                    <MegaMenuLink to={megamenuLink.to} key={i}>
                                        <MegaMenuIcon src={megamenuLink.icon} alt='' />
                                        {megamenuLink.title}
                                    </MegaMenuLink>
                                ))}
                        </MegaMenu>
                        {/* <MegaMenu className={megaMenuOpened ? 'open' : ''} ref={resMenu}>
                                {megamenuLinks.map((megamenuLink, i) => (
                                    <MegaMenuLink to={megamenuLink.to} key={i}>
                                        <MegaMenuIcon src={megamenuLink.icon} alt='' />
                                        {megamenuLink.title}
                                    </MegaMenuLink>
                                ))}
                        </MegaMenu> */}
                            <ResourcesMenu className={resourcesMenuOpened ? 'open' : ''} ref={resMenu}>
                                {resourcesMenuLinks.map((resourcesMenuLink, i) => (
                                    <ResourcesMenuLink to={resourcesMenuLink.to} key={i}>
                                        {resourcesMenuLink.title}
                                        <Arrow />
                                    </ResourcesMenuLink>
                                ))}
                            </ResourcesMenu>
                        {mobileMenuOpened && <MobileNavOverlay />}
                        {!mobileMenuOpened && <MobileMenuButton onClick={() => {setMobileMenuOpened(true); mobileMenuLaunchHandler();}}>
                                <img src={mobileMenuIcon} alt=''/>
                                MENU
                        </MobileMenuButton>}
                        <MobileMenu className={mobileMenuOpened ? 'open' : ''} ref={mobileMenu}>
                            <CloseBar>
                                {mobileMegaMenuOpened && <MegaMenuClose onClick={() => setMobileMegaMenuOpened(false)}>
                                    <img src={leftIcon} alt=''/>
                                    <Subtitle>Back</Subtitle>
                                </MegaMenuClose>}
                                {mobileResourceMenuOpened && <MegaMenuClose onClick={() => setMobileResourceMenuOpened(false)}>
                                    <img src={leftIcon} alt=''/>
                                    <Subtitle>Back</Subtitle>
                                </MegaMenuClose>}
                                <MobileMenuButton onClick={() => setMobileMenuOpened(false)}>
                                    <img src={closeIcon} alt=''/>
                                    CLOSE
                                </MobileMenuButton>
                            </CloseBar>
                            <MainNavItems>
                                <Subtitle><button onClick={() => setMobileMegaMenuOpened(true)}>Efficacy <img src={rightIcon} alt='' /></button></Subtitle>
                                <Subtitle><Link to='/safety'>Safety</Link></Subtitle>
                                <Subtitle><Link to='/dosing'>Dosing</Link></Subtitle>
                                <Subtitle><Link to='/patient-support'>Patient Support</Link></Subtitle>
                                <Subtitle><button onClick={() => setMobileResourceMenuOpened(true)}>Resources <img src={rightIcon} alt='' /></button></Subtitle>
                            </MainNavItems>
                            <UtilityNavItems>
                                <AskMerckButton href='https://www.askmerck.com/' target='_blank'>
                                    Ask Merck
                                </AskMerckButton>
                                <Button type='tertiary-dark' className='external' noIcon onClick={() => {
                                dispatch(openGeneralModal())
                                dispatch(preventScrolling())
                            }}><Subtitle>Medical Information</Subtitle></Button>
                                <Button type='tertiary-dark' className='external' noIcon to='https://www.keytrudalenvima.com/' target='_blank'><Subtitle>Visit Patient Site</Subtitle></Button>
                            </UtilityNavItems>
                            <MobileMegaMenu className={mobileMegaMenuOpened ? 'open' : ''}>
                                {megamenuLinks.map((megamenuLink, i) => (
                                    <MegaMenuLink to={megamenuLink.to} key={i}>
                                        <MegaMenuIcon src={megamenuLink.icon} alt='' />
                                        {megamenuLink.title}
                                    </MegaMenuLink>
                                ))}
                            </MobileMegaMenu>
                            <MobileResourceMenu className={mobileResourceMenuOpened ? 'open' : ''}>
                                {resourcesMenuLinks.map((megamenuLink, i) => (
                                    <MegaMenuLink to={megamenuLink.to} key={i}>
                                        {megamenuLink.title}
                                    </MegaMenuLink>
                                ))}
                            </MobileResourceMenu>
                        </MobileMenu>
                    </GlobalNavContainer>
                </GlobalNav>
                {name && <PageHeader hide={hidePageHeader} name={name} icon={icon} iconAlt={iconAlt}/>}
                {anchorNavLinks && <AnchorNav>
                    {Array.isArray(anchorNavLinks) && anchorNavLinks.map((navLink, i) => 
                        <AnchorLink to={navLink.href} key={i} className={navLink.style} title={navLink.title} />
                    )}
                </AnchorNav>}
                </NavHeader>
            </Headroom>
        </NavBarContainer>
)};
export default NavBar;
